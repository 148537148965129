<template>
  <div v-loading="loading">
    <el-card
        class="box-card"
        v-for="(item1, index1) in rules"
        :key="index1" v-if="item1.deputy_id === '0' && item1.isdel === '0'"
        style="margin-bottom: 5px"
    >
      <div slot="header" class="clearfix" style="text-align: left" >
        <el-checkbox :indeterminate="allRuleIdKeyList[item1.id].isIndeterminate" v-model="allRuleIdKeyList[item1.id].checked" @change="handleChange(allRuleIdKeyList[item1.id].checked, item1.id)" ><span style="font-size: 1.1rem">{{ item1.name }}</span></el-checkbox>
      </div>
      <div
          v-for="(item2, index2) in item1.children"
          :key="index2"
          class="text item" v-if="item2.deputy_id === '0'"
      >
        <el-collapse v-model="activeNames"  v-show="item1.children.length > 0">
          <el-collapse-item :name="item2.id" v-if="item2.isdel === '0'">
            <template slot="title">
              <el-checkbox :indeterminate="allRuleIdKeyList[item2.id].isIndeterminate" v-model="allRuleIdKeyList[item2.id].checked" @change="handleChange(allRuleIdKeyList[item2.id].checked, item2.id)" ><span style="font-size: 0.9rem">{{ item2.name }}</span></el-checkbox>
            </template>
            <el-card class="box-card" style="text-align: left" v-show="item2.children.length > 0">
              <div v-for="(item3, index3) in item2.children" :key="index3" v-if="item3.deputy_id === '0' && item3.isdel === '0'">
                <el-checkbox :indeterminate="allRuleIdKeyList[item3.id].isIndeterminate" v-model="allRuleIdKeyList[item3.id].checked" @change="handleChange(allRuleIdKeyList[item3.id].checked, item3.id)" ><span style="font-weight: bold">{{ item3.name }}</span></el-checkbox>

                <label v-for="(item4, index4) in item3.children" :key="index4" v-if="item4.deputy_id === '0' && item4.isdel === '0'">
                  <el-checkbox :indeterminate="allRuleIdKeyList[item4.id].isIndeterminate"  v-model="allRuleIdKeyList[item4.id].checked"  @change="handleChange(allRuleIdKeyList[item4.id].checked, item4.id)" >{{ item4.name }}</el-checkbox>
                </label>
              </div>
            </el-card>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
    <el-button plain @click="addRule()"
    >重置授权</el-button>
    <el-button type="primary" @click="onSubmit()"
    >确认授权</el-button>
  </div>
</template>

<script>
  export default {
    name: "RoleAuthorize",
    components: {},
    data() {
      return {
        loading: false,
        formData: {},
        rules: [],
        activeNames: [],
        isIndeterminate: false,
        allRuleIdKeyList: [],
        allRulePidKeyList: [],
        auth_role_id: '',
      };
    },
    methods: {
      onSubmit() {
        //验证通过执行请求
        this.loading = true;
        this.myfun
          .request('adminUserRoleAuthorize.api', {auth_role_id: this.auth_role_id, allRuleIdKeyList: JSON.stringify(this.allRuleIdKeyList)}, "post")
          .then((result) => {
            if (result.code == "1") {
              this.roleAuthorizeDeputy();
              this.$message({
                message: result.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: result.msg,
                type: "warning",
              });
            }
            this.loading = false;
          });
      },

      /**
       * 处理授权改变状态
       * @param val
       * @param id
       * @param handleChildren 是否处理下级
       * @param handleParent 是否处理上级
       */
      handleChange(val, id, handleChildren = true, handleParent = true) {
        if(typeof(this.allRulePidKeyList[id]) !== 'undefined'){
          //循环设置下级的选中状态
          for (let i in this.allRulePidKeyList[id].children){
            if(handleChildren){
              this.allRuleIdKeyList[this.allRulePidKeyList[id].children[i].id].checked = val;
              this.handleChange(val, this.allRulePidKeyList[id].children[i].id); //设置下级的下级选中状态

            }

          }
        }else{
          // console.log('没有')
        }

        if(handleParent){
          //处理上级选中状态
          this.handleParentCheckedChange(this.allRuleIdKeyList[id].pid);
        }



        this.rules.splice(); // 强制更新视图 否则可能出现无法取消选中的bug
      },
      //处理上级选中状态
      handleParentCheckedChange(pid){
        if(pid <= 0){
          return;
        }
        //判断上级下的所有子级是否都未选中 如果都未选中则取消上级选中 如果部分未选中则上级状态改为部分选中
        //循环上级的所有子级 获取子级选中的数量
        let checkedChildrenNum = 0;
        let isIndeterminateChildrenNum = 0;
        let pChildren = this.allRulePidKeyList[pid].children;
        for(let i in pChildren){
          if(typeof(this.allRuleIdKeyList[pChildren[i].id].checked) !== 'undefined' && this.allRuleIdKeyList[pChildren[i].id].checked){
            checkedChildrenNum++;
          }else if(typeof(this.allRuleIdKeyList[pChildren[i].id].isIndeterminate) !== 'undefined' && this.allRuleIdKeyList[pChildren[i].id].isIndeterminate){
            isIndeterminateChildrenNum++;
          }
        }

        let pChecked = typeof(this.allRuleIdKeyList[pid].checked) === 'undefined' ? false : this.allRuleIdKeyList[pid].checked; //记录上级的选中状态
        let pIsIndeterminate = typeof(this.allRuleIdKeyList[pid].isIndeterminate) === 'undefined' ? false : this.allRuleIdKeyList[pid].isIndeterminate; //记录上级的部分选中状态
        if(checkedChildrenNum === 0 && isIndeterminateChildrenNum === 0){ //没有选中的 则将上级改为未选中
          if(this.allRuleIdKeyList[pid].level === '3' && (pChecked === true || pIsIndeterminate === true)){ //如果上级为第三级 并且之前为选中状态或者部分选中状态 则在子级导致的未选中的时候 还保持部分选中状态
            this.allRuleIdKeyList[pid].checked = false;
            this.allRuleIdKeyList[pid].isIndeterminate = false;
          }else{
            this.allRuleIdKeyList[pid].isIndeterminate = false;
            this.allRuleIdKeyList[pid].checked = false;
          }
        }else if(pChildren.length > checkedChildrenNum){ //部分子级选中(含部分选中子级)
          this.allRuleIdKeyList[pid].checked = false;
          this.allRuleIdKeyList[pid].isIndeterminate = true;
        }else{ //全选中
          this.allRuleIdKeyList[pid].isIndeterminate = false;
          this.allRuleIdKeyList[pid].checked = true;
        }
        this.handleParentCheckedChange(this.allRuleIdKeyList[pid].pid);
        // console.log(this.allRuleIdKeyList[pid].name+'子级总数'+pChildren.length+', 选中'+checkedChildrenNum+', 部分选中'+isIndeterminateChildrenNum)
      },
      doSomething() {
        console.log("阻止冒泡");
      },
      roleAuthorizeDeputy(){
        //验证通过执行请求
        this.loading = true;
        this.myfun
          .request("adminUserRoleAuthorizeDeputy.api", {auth_role_id: this.auth_role_id}, "post")
          .then((result) => {
            if (result.code == "1") {
              //循环设置已拥有权限
              let allAuthRuleIds = result.data.allAuthRuleIds;
              for (let i in allAuthRuleIds){
                try {
                  this.allRuleIdKeyList[allAuthRuleIds[i]].checked = true;
                  this.handleChange(true, allAuthRuleIds[i], false, false)
                }catch (e) {
                  console.log('不存在的权限:'+allAuthRuleIds[i])
                }

              }
              //循环处理自身 如果嵌套在设置权限里边 很可能会出现先处理上级 那么下级还没处理 就会导致上级变为未选中状态
              for (let i in allAuthRuleIds){
                //如果当前全下有下级存在 则需要处理自身状态
                if(typeof(this.allRulePidKeyList[allAuthRuleIds[i]]) !== 'undefined' && this.allRulePidKeyList[allAuthRuleIds[i]].children.length > 0){
                  if(typeof(this.allRulePidKeyList[allAuthRuleIds[i]].deputy_id) !== 'undefined' && this.allRulePidKeyList[allAuthRuleIds[i]].deputy_id > 0){
                    if(this.allRulePidKeyList[allAuthRuleIds[i]].children.length > 1){ //副接口不作为是否处理的标识
                      this.handleParentCheckedChange(allAuthRuleIds[i])
                    }
                  }else{
                    this.handleParentCheckedChange(allAuthRuleIds[i])
                  }
                }
              }


            } else {
              this.$message({
                message: result.msg,
                type: "warning",
              });
            }
            this.loading = false;
          });
      }
    },
    created() {

      //请求角色授权副接口
      let userInfo = this.myfun.myGetStorage('userInfo');
      this.allRuleIdKeyList = userInfo.allRuleIdKeyList;
      this.allRulePidKeyList = userInfo.allRulePidKeyList;
      this.activeNames = userInfo.allAuthRuleIds;
      this.rules = userInfo.rules;

      this.auth_role_id = this.$route.query.id;

      this.roleAuthorizeDeputy();


    },
    mounted() {
      //
    },
    computed: {
      //
    },
  };
</script>

<style scoped>
  .edit-rule-btn{
    margin-left: 3px;
  }
  .edit-rule-btn:nth-of-type(even){
    margin-right: 30px;
  }
</style>
