<template>
  <div v-loading="loading">
    <el-row style="text-align: left; margin: 0 0 10px 0">
      <el-button type="primary" @click="addRule()"
        >新增权限</el-button>
    </el-row>
    <el-card
      class="box-card"
      v-for="(item1, index1) in rules"
      :key="index1"
      style="margin-bottom: 5px"
    >
      <div slot="header" class="clearfix" style="text-align: left">
        <span style="font-size: 1.1rme">{{ item1.name }}</span>
        <el-button class="edit-rule-btn" type="text" @click="editRule(item1)">[编辑]</el-button>
        <el-button v-if="typeof(allRulePidKeyList[item1.id]) !== 'undefined' && typeof(allRulePidKeyList[item1.id].deputy_id) !== 'undefined'"
          type="text"
          @click="editRule(allRuleIdKeyList[allRulePidKeyList[item1.id].deputy_id])"
          style="margin-left: 0">[副接口]</el-button>
        <el-button
          class="edit-rule-btn"
          type="text"
          @click="delRule(item1.id)"
          style="margin-left: 0">[删除]</el-button>
      </div>
      <div
        v-for="(item2, index2) in item1.children"
        :key="index2" v-if="item2.deputy_id === '0'"
        class="text item"
      >
        <el-collapse v-model="activeNames" @change="handleChange" v-show="item1.children.length > 0">
          <el-collapse-item :name="item2.id">
            <template slot="title">
              <span style="font-size: 0.9rem">{{ item2.name }}</span>
              <el-button
                class="edit-rule-btn"
                type="text"
                @click="editRule(item2)"
                v-on:click.native.stop="doSomething"
                >[编辑]</el-button>
              <el-button v-if="typeof(allRulePidKeyList[item2.id]) !== 'undefined' && typeof(allRulePidKeyList[item2.id].deputy_id) !== 'undefined'"
                         type="text"
                         @click="editRule(allRuleIdKeyList[allRulePidKeyList[item2.id].deputy_id])"
                         style="margin-left: 0">[副接口]</el-button>
              <el-button
                class="edit-rule-btn"
                type="text"
                @click="delRule(item2.id)"
                v-on:click.native.stop="doSomething" style="margin-left: 0"
                >[删除]</el-button>
            </template>
            <el-card class="box-card" style="text-align: left" v-show="item2.children.length > 0">
              <div v-for="(item3, index3) in item2.children" :key="index3" v-if="item3.deputy_id === '0'">
                <label style="font-weight: bold"
                  >{{ item3.name }}
                  <el-button
                    class="edit-rule-btn"
                    type="text"
                    @click="editRule(item3)"
                    >[编辑]</el-button>
                  <el-button v-if="typeof(allRulePidKeyList[item3.id]) !== 'undefined' && typeof(allRulePidKeyList[item3.id].deputy_id) !== 'undefined'"
                             type="text"
                             @click="editRule(allRuleIdKeyList[allRulePidKeyList[item3.id].deputy_id])"
                             style="margin-left: 0">[副接口]</el-button>
                  <el-button
                    class="edit-rule-btn"
                    type="text"
                    @click="delRule(item3.id)" style="margin-left: 0"
                    >[删除]</el-button>
                </label>
                <label v-for="(item4, index4) in item3.children" :key="index4" v-if="item4.deputy_id === '0'"
                  >{{ item4.name }}
                  <el-button
                    class="edit-rule-btn"
                    type="text"
                    @click="editRule(item4)"
                    >[编辑]</el-button>
                  <el-button v-if="typeof(allRulePidKeyList[item4.id]) !== 'undefined' && typeof(allRulePidKeyList[item4.id].deputy_id) !== 'undefined'"
                             type="text"
                             @click="editRule(allRuleIdKeyList[allRulePidKeyList[item4.id].deputy_id])"
                             style="margin-left: 0">[副接口]</el-button>
                  <el-button class="edit-rule-btn"  style="margin-left: 0" type="text"  @click="delRule(item4.id)">[删除]</el-button>
                </label>
              </div>
            </el-card>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>

    <!-- Form -->

    <el-dialog
      :title="operateTitle"
      :visible.sync="dialogFormVisible"
      v-loading="loading"
    >
      <el-form :model="formData" :rules="checkRules" ref="formData">
        <el-form-item
          label="级别"
          :label-width="formLabelWidth"
          prop="ruleLevelValue"
          ref="ruleLevelValue"
          required
        >
          <el-select
            v-model="formData.ruleLevelValue"
            clearable
            placeholder="请选择"
            @change="changeRuleLevel" :disabled="formData.id !== '0'"
            style="width: 100%"
          >
            <el-option
              v-for="item in formData.ruleLevelArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          :label-width="formLabelWidth"
          ref="ruleLevel1"
          v-show="formData.ruleLevel1Show"
          prop="ruleLevel1Value"
        >
          <el-select
            v-model="formData.ruleLevel1Value"
            clearable
            placeholder="请选择"
            @change="changeRuleLevel1"
            style="width: 100%"
          >
            <el-option
              v-for="item in formData.ruleLevel1Arr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          :label-width="formLabelWidth"
          ref="ruleLevel2"
          v-show="formData.ruleLevel2Show"
          prop="ruleLevel2Value"
        >
          <el-select
            v-model="formData.ruleLevel2Value"
            clearable
            placeholder="请选择"
            @change="changeRuleLevel2"
            style="width: 100%"
          >
            <el-option
              v-for="item in formData.ruleLevel2Arr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label=""
          :label-width="formLabelWidth"
          ref="ruleLevel3"
          v-show="formData.ruleLevel3Show"
          prop="ruleLevel3Value"
        >
          <el-select
            v-model="formData.ruleLevel3Value"
            clearable
            placeholder="请选择"
            @change="changeRuleLevel3"
            style="width: 100%"
          >
            <el-option
              v-for="item in formData.ruleLevel3Arr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label=""
            :label-width="formLabelWidth"
            ref="ruleLevel4"
            v-show="formData.ruleLevel4Show"
            prop="ruleLevel4Value"
        >
          <el-select
              v-model="formData.ruleLevel4Value"
              clearable
              placeholder="请选择"
              @change="changeRuleLevel4"
              style="width: 100%"
          >
            <el-option
                v-for="item in formData.ruleLevel4Arr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="名称"
          :label-width="formLabelWidth"
          prop="name"
          required>
          <el-input v-model="formData.name" autocomplete="off" :disabled="formData.isDeputy"></el-input>
        </el-form-item>
        <el-form-item
          label="权限标识"
          :label-width="formLabelWidth"
          prop="moduleName"
          required
        >
          <el-input
            v-model="formData.moduleName"
            placeholder="权限唯一标识"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="前端路由" :label-width="formLabelWidth" prop="clientRoutePath">
          <el-input
            v-model="formData.clientRoutePath"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="是否导航"
          :label-width="formLabelWidth"
          style="text-align: left" prop="isNav"
        >
          <el-switch
            v-model="formData.isNav"
            active-color="#13ce66"
            inactive-color="#ff4949" :disabled="formData.isDeputy"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          label="图标"
          :label-width="formLabelWidth"
          v-show="formData.isNav && formData.ruleLevelValue == '1'" prop="icon"
        >
          <el-input v-model="formData.icon" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="是否副接口"
          :label-width="formLabelWidth"
          style="text-align: left" prop="isDeputy"
        >
          <el-switch
            v-model="formData.isDeputy"
            active-color="#13ce66"
            inactive-color="#ff4949" :disabled="formData.id !== '0'" @change="handleIsDeputyChange"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
            label="是否删除"
            :label-width="formLabelWidth"
            style="text-align: left" prop="isDel"
        >
          <el-switch
              v-model="formData.isDel"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth" prop="sort">
          <el-input
            v-model="formData.sort"
            placeholder="数值越大，排序越靠前"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="text" plain  @click="delRule(formData.id)" style="float: left; color: #c0ccda" v-if="formData.id !== '0' && formData.isDeputy">删 除</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('formData')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AuthRuleManage",
  components: {},
  data() {
    return {
      loading: false,
      operateTitle: '新增权限',
      rules: [],
      activeNames: [],
      dialogFormVisible: false,
      formData: {},
      checkRules: {
        ruleLevelValue: [
          { required: true, message: "请选择权限级别"},
        ],
        name: [
          { required: true, message: "请输入权限名称"}
        ],
        moduleName: [
          { required: true, message: "请输入权限标识"},
        ],
      },
      formLabelWidth: "120px",
      allRuleIdKeyList: [],
      allRulePidKeyList: []
    };
  },
  methods: {
    resetFormData(formName = 'formData'){
      this.formData = {
        id: "0",
        name: "",
        isDeputy: false, //是否副接口
        isNav: false, //是否导航
        isDel: false, //是否删除
        icon: "",
        clientRoutePath: "",
        moduleName: "",
        sort: "",
        ruleLevelValue: "",
        ruleLevelArr: [
          {
            value: "1",
            label: "一级",
          },
          {
            value: "2",
            label: "二级",
          },
          {
            value: "3",
            label: "三级",
          },
          {
            value: "4",
            label: "四级",
          },
          {
            value: "5",
            label: "五级",
          }
        ],
        ruleLevel1Show: false,
        ruleLevel1Value: "",
        ruleLevel1Arr: [],
        ruleLevel2Show: false,
        ruleLevel2Value: "",
        ruleLevel2Arr: [],
        ruleLevel3Show: false,
        ruleLevel3Value: "",
        ruleLevel3Arr: [],
        ruleLevel4Show: false,
        ruleLevel4Value: "",
        ruleLevel4Arr: [],
      };

      this.rules = this.myfun.myGetStorage("userInfo").rules;
      //设置级别1列表
      for (let i in this.rules) {
        this.formData.ruleLevel1Arr.push({
          value: this.rules[i].id,
          label: this.rules[i].name,
        });
      }


      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
    },
    getList() {
      //验证通过执行请求
      this.loading = true;
      this.myfun
        .request("adminUserGetAuthRuleList.api", {}, "post")
        .then((result) => {
          if (result.code == "1") {
            let userInfo = this.myfun.myGetStorage("userInfo");
            userInfo.rules = result.data.rules;
            userInfo.allAuthRuleIds = result.data.allAuthRuleIds;
            userInfo.menus = result.data.menus;
            userInfo.allRuleIdKeyList = result.data.allRuleIdKeyList;
            userInfo.allRulePidKeyList = result.data.allRulePidKeyList;
            userInfo.allAuthRuleModuleNameKeyList = result.data.allAuthRuleModuleNameKeyList;
            this.$store.commit('setAllAuthRuleModuleNameKeyList', result.data.allAuthRuleModuleNameKeyList); //设置授权列表
            this.allRuleIdKeyList = userInfo.allRuleIdKeyList;
            this.allRulePidKeyList = userInfo.allRulePidKeyList;

            this.$store.commit('setMenus', userInfo.menus)
            this.myfun.mySetStorage(
              "userInfo",
              userInfo,
              userInfo.tokenExpress,
              true
            );
            this.rules = this.myfun.myGetStorage("userInfo").rules;
            this.activeNames = this.myfun.myGetStorage(
              "userInfo"
            ).allAuthRuleIds;
          } else {
            this.$message({
              message: result.msg,
              type: "warning",
            });
          }
          this.loading = false;
        });
    },
    addRule(){
      this.resetFormData();

      // this.$refs['formData'].clearValidate(['ruleLevelValue','name','moduleName']);
      this.operateTitle = '新增权限';
      this.dialogFormVisible = true;
    },
    editRule(item) {
      console.log(item.level)
      this.operateTitle = '编辑权限';
      this.formData.id = item.id;
      this.formData.name = item.name;
      this.formData.isDeputy = item.deputy_id > 0;
      this.formData.isNav = item.isnav > 0;
      this.formData.isDel = item.isdel > 0;
      this.formData.icon = item.icon;
      this.formData.clientRoutePath = item.client_route_path;
      this.formData.moduleName = item.module_name;
      this.formData.sort = item.sort;
      this.formData.ruleLevelValue = item.level;
      this.changeRuleLevel(item.level);
      if(item.deputy_id === '0'){
        if(item.level == '1'){

        }else if(item.level === '2'){
          this.formData.ruleLevel1Value = item.pid;
          this.changeRuleLevel1(item.pid);
        }else if(item.level === '3'){
          let pid2 = item.pid;
          let pid1 = this.myfun.myGetStorage('userInfo').allRuleIdKeyList[pid2].pid;

          this.formData.ruleLevel1Value = pid1;
          this.changeRuleLevel1(pid1);
          this.formData.ruleLevel2Value = pid2;
          this.changeRuleLevel2(pid2);
        }else if(item.level === '4'){
          let pid3 = item.pid;
          let pid2 = this.myfun.myGetStorage('userInfo').allRuleIdKeyList[pid3].pid;
          let pid1 = this.myfun.myGetStorage('userInfo').allRuleIdKeyList[pid2].pid;
          this.formData.ruleLevel1Value = pid1;
          this.changeRuleLevel1(pid1);
          this.formData.ruleLevel2Value = pid2;
          this.changeRuleLevel2(pid2);
          this.formData.ruleLevel3Value = pid3;
          this.changeRuleLevel3(pid3);
        }else if(item.level === '5'){
          let pid4 = item.pid;
          let pid3 = this.myfun.myGetStorage('userInfo').allRuleIdKeyList[pid4].pid;
          let pid2 = this.myfun.myGetStorage('userInfo').allRuleIdKeyList[pid3].pid;
          let pid1 = this.myfun.myGetStorage('userInfo').allRuleIdKeyList[pid2].pid;
          this.formData.ruleLevel1Value = pid1;
          this.changeRuleLevel1(pid1);
          this.formData.ruleLevel2Value = pid2;
          this.changeRuleLevel2(pid2);
          this.formData.ruleLevel3Value = pid3;
          this.changeRuleLevel3(pid3);
        }
      }else{
        this.formData.ruleLevel1Show = false;
        this.formData.ruleLevel2Show = false;
        this.formData.ruleLevel3Show = false;
        this.formData.ruleLevel4Show = false;
      }



      this.dialogFormVisible = true;

    },
    delRule(id) {
      this.$confirm("此操作将永久删除该权限, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.myfun
            .request("adminUserDelAuthRule.api", { id: id }, "post")
            .then((result) => {
              if (result.code == "1") {
                this.getList();
                this.$message({
                  message: result.msg,
                  type: "success",
                });
                this.dialogFormVisible = false;
              } else {
                this.$message({
                  message: result.msg,
                  type: "warning",
                });
              }
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleChange(val) {
      console.log(val);
    },
    changeRuleLevel(val) {
      this.formData.ruleLevel1Show = false;
      this.formData.ruleLevel2Show = false;
      this.formData.ruleLevel3Show = false;
      this.formData.ruleLevel4Show = false;
      switch (val) {
        case "1":
          break;
        case "2":
          this.formData.ruleLevel1Show = true;
          break;
        case "3":
          this.formData.ruleLevel1Show = true;
          this.formData.ruleLevel2Show = true;
          break;
        case "4":
          this.formData.ruleLevel1Show = true;
          this.formData.ruleLevel2Show = true;
          this.formData.ruleLevel3Show = true;
          break;
        case "5":
          this.formData.ruleLevel1Show = true;
          this.formData.ruleLevel2Show = true;
          this.formData.ruleLevel3Show = true;
          this.formData.ruleLevel4Show = true;
          break;
      }
    },
    changeRuleLevel1(val) {
      this.formData.ruleLevel2Arr = [];
      this.formData.ruleLevel3Arr = [];
      this.formData.ruleLevel2Value = '';
      this.formData.ruleLevel3Value = '';
      //设置2级权限
      for (let i in this.rules) {
        if (this.rules[i].id === val) {
          for (let j in this.rules[i].children) {
            this.formData.ruleLevel2Arr.push({
              value: this.rules[i].children[j].id,
              label: this.rules[i].children[j].name,
            });
          }
        }
      }

      this.handleIsDeputyChange();
    },
    changeRuleLevel2(val) {
      this.formData.ruleLevel3Arr = [];
      this.formData.ruleLevel3Value = '';
      //设置3
      for (let i in this.rules) {
        for (let j in this.rules[i].children) {
          if (this.rules[i].children[j].id === val) {
            for (let k in this.rules[i].children[j].children) {
              this.formData.ruleLevel3Arr.push({
                value: this.rules[i].children[j].children[k].id,
                label: this.rules[i].children[j].children[k].name,
              });
            }
          }
        }
      }

      this.handleIsDeputyChange();
    },
    changeRuleLevel3(val) {
      this.formData.ruleLevel4Arr = [];
      this.formData.ruleLevel4Value = '';
      if(typeof(this.allRulePidKeyList[val]) !== 'undefined'){
        for (let i in this.allRulePidKeyList[val].children) {
          this.formData.ruleLevel4Arr.push({
            value: this.allRulePidKeyList[val].children[i].id,
            label: this.allRulePidKeyList[val].children[i].name,
          });
        }
      }
      this.handleIsDeputyChange();
    },
    changeRuleLevel4(val) {
      this.handleIsDeputyChange();
    },
    handleIsDeputyChange(){
      if(this.formData.isDeputy){
        if(this.formData['ruleLevelValue'] <= 1){
          this.formData.isDeputy = false;
          this.$message({
            message: '一级权限不允许设置副接口',
            type: "warning",
          });
          this.formData.name = '';
        }else{
          if(typeof(this.allRuleIdKeyList[this.formData['ruleLevel'+(this.formData['ruleLevelValue'] - 1)+'Value']]) === 'undefined'){
            this.formData.isDeputy = false;
            this.$message({
              message: '请设置所属上级权限',
              type: "warning",
            });
            this.formData.name = '';
          }else{
            this.formData.name = this.allRuleIdKeyList[this.formData['ruleLevel'+(this.formData['ruleLevelValue'] - 1)+'Value']].name+'丨副';
            this.formData.isNav = false;
          }

        }
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          let apiName = this.formData.id === '0' ? 'adminUserAddAuthRule.api' : 'adminUserEditAuthRule.api';
          this.myfun
            .request(apiName, this.formData, "post")
            .then((result) => {
              if (result.code == "1") {
                this.getList();
                this.$message({
                  message: result.msg,
                  type: "success",
                });
                this.dialogFormVisible = false;
              } else {
                this.$message({
                  message: result.msg,
                  type: "warning",
                });
              }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    doSomething() {
      console.log("阻止冒泡");
    },
  },
  created() {

    let userInfo = this.myfun.myGetStorage('userInfo');
    this.allRuleIdKeyList = userInfo.allRuleIdKeyList;
    this.allRulePidKeyList = userInfo.allRulePidKeyList;

    this.activeNames = this.myfun.myGetStorage("userInfo").allAuthRuleIds;

    this.resetFormData();


  },
  mounted() {
    //
  },
  computed: {
    //
  },
};
</script>

<style scoped>
  .edit-rule-btn{
    margin-left: 3px;
  }
  .edit-rule-btn:nth-of-type(even){
    margin-right: 30px;
  }
</style>
