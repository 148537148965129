<template>
  <div>
    <el-row style="text-align: left; margin: 0 0 10px 0">
      <el-button type="primary" @click="addRole()"
      >新增角色</el-button
      >
    </el-row>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID"
          >
      </el-table-column>
      <el-table-column
          prop="name"
          label="名称"
          >
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="状态"
         >
      </el-table-column>
      <el-table-column

          label="操作"
          >
        <template slot-scope="scope">
          <el-button @click="myfun.myRouterAutoPush('/user/auth-role-authorize', {id: scope.row.id})" type="text" size="small" v-if="myfun.checkAuthRule('adminUserRoleAuthorize')">授权</el-button>
          <el-button @click="editRole(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminUserEditRole')">编辑</el-button>
          <el-button @click="delRole(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminUserDelRole')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>



    <!-- Form -->

    <el-dialog
        :title="operateTitle"
        :visible.sync="dialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="formData" :rules="checkRules" ref="formData">
        <el-form-item
            label="名称"
            :label-width="formLabelWidth"
            prop="name"
            required>
          <el-input v-model="formData.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="是否开启"
            :label-width="formLabelWidth"
            style="text-align: left" prop="status"
        >
          <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('formData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'RoleList',
    components: {
    },
    data() {
      return {
        operateTitle: '新增角色',
        dialogFormVisible: false,
        formData:{},
        tableData:[],
        loading: false,
        formLabelWidth: "80px",
        checkRules: {
          name: [
            { required: true, message: "请输入角色名称"}
          ]
        }
      }
    },
    methods: {
      resetFormData(formName = 'formData'){
        this.formData = {
          id: '0',
          name: '',
          status: true,
          page: 1,
          pagesize: 10,
          total: 0
        };

        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      },
      getList(){
        this.tableData = [];
        this.loading = true;
        this.myfun.request('adminUserGetRoleList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      addRole(){
        this.resetFormData();

        // this.$refs['formData'].clearValidate(['ruleLevelValue','name','moduleName']);
        this.operateTitle = '新增角色';
        this.dialogFormVisible = true;
      },
      editRole(row) {
        this.operateTitle = '编辑角色';
        this.formData.id = row.id;
        this.formData.name = row.name;
        this.formData.status = row.status == '1';

        this.dialogFormVisible = true;
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            let apiName = this.formData.id === '0' ? 'adminUserAddRole.api' : 'adminUserEditRole.api';
            console.log(apiName)
            this.myfun
              .request(apiName, this.formData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.dialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },

      delRole(row) {
        this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.myfun
              .request("adminUserDelRole.api", { id: row.id }, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
    },
    created() {
      this.getList();
      this.resetFormData();
    },
    mounted() {
    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style scoped>

</style>
