<template>
  <div>
    <el-row style="text-align: left; margin: 0 0 10px 0">
      <el-button type="primary" @click="addUser()"
      >新增用户</el-button
      >
    </el-row>
    <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        style="width: 100%" max-height="600">
      <el-table-column
          prop="id"
          label="#ID"
          >
      </el-table-column>
      <el-table-column
          prop="uname"
          label="用户名"
          >
      </el-table-column>
      <el-table-column
          prop="full_name"
          label="姓名"
          >
      </el-table-column>
      <el-table-column
          prop="auth_role.name"
          label="角色"
          >
      </el-table-column>
      <el-table-column
          prop="mobile"
          label="手机">
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="状态">
      </el-table-column>
      <el-table-column
          prop="last_time"
          label="登录时间"
          width="100">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="注册时间"
          width="100">
      </el-table-column>
      <el-table-column

          label="操作"
          >
        <template slot-scope="scope">
          <el-button  @click="myfun.myRouterAutoPush('/user/auth-user-authorize', {id: scope.row.id})" type="text" size="small" v-if="myfun.checkAuthRule('adminUserAuthorize')">授权</el-button>
          <el-button @click="editUser(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminUserEditUser')">编辑</el-button>
          <el-button @click="delUser(scope.row)" type="text" size="small" v-if="myfun.checkAuthRule('adminUserDelUser')">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination style="margin-top: 1rem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formData.page"
          :page-sizes="pageSizs"
          :page-size="formData.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formData.total">
      </el-pagination>
    </div>

    <!-- Form -->

    <el-dialog
        :title="operateTitle"
        :visible.sync="dialogFormVisible"
        v-loading="loading"
    >
      <el-form :model="formData" :rules="checkRules" ref="formData">
        <el-form-item
            label="用户名"
            :label-width="formLabelWidth"
            prop="uname"
            required>
          <el-input v-model="formData.uname" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="所属角色"
            :label-width="formLabelWidth"
            ref="authRoleId"
            prop="authRoleId" required
        >
          <el-select
              v-model="formData.authRoleId"
              clearable
              placeholder="请选择"
              style="width: 100%"
          >
            <el-option
                v-for="item in authRoleList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="姓名"
            :label-width="formLabelWidth"
            prop="full_name"
            required>
          <el-input v-model="formData.full_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="手机号"
            :label-width="formLabelWidth"
            prop="mobile"
            required>
          <el-input v-model="formData.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="密码"
            :label-width="formLabelWidth"
            prop="password"
            required>
          <el-input type="password" v-model="formData.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
            label="是否开启"
            :label-width="formLabelWidth"
            style="text-align: left" prop="status"
        >
          <el-switch
              v-model="formData.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('formData')"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'UserList',
    components: {
    },
    data() {
      return {
        operateTitle: '新增用户',
        formData: {},
        tableData:[],
        loading: false,
        dialogFormVisible: false,
        checkRules: {
          uname: [
            { required: true, message: "请输入用户名"},
          ],
          authRoleId: [
            { required: true, message: "请选择所属角色"},
          ],
          full_name: [
            { required: true, message: "请输入姓名"},
          ],
          mobile: [
            { required: true, message: "请输入手机号"},
          ],
          password: [
            { required: true, message: "请输入密码"},
          ]
        },
        formLabelWidth: "80px",
        authRoleList:[],
      }
    },
    methods: {
      resetFormData(formName = 'formData'){
        this.formData = {
          id: '0',
          uname: '',
          authRoleId:'',
          full_name: '',
          mobile: '',
          password: '',
          status: true,
          page: 1,
          pagesize: 10,
          total: 0
        };

        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }

        //请求副接口
        if(this.authRoleList.length === 0){
          this.loading = true;
          this.myfun.request('adminUserAddUserDeputy.api', {}, 'post').then((result) => {
            if (result.code == '1') {
              for(let i in result.data.roleList){
                this.authRoleList.push({
                  value: result.data.roleList[i].id,
                  label: result.data.roleList[i].name,
                });
              }
            } else {
              this.$message({
                message: result.msg,
                type: 'warning'
              });
            }
            this.loading = false;
          });
        }

      },
      getList(){
        this.tableData = [];
        this.loading = true;
        this.myfun.request('adminUserGetUserList.api', this.formData, 'post').then((result) => {
          if (result.code == '1') {
            this.tableData = result.data.list;
          } else {
            this.$message({
              message: result.msg,
              type: 'warning'
            });
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
      },
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.formData.pagesize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.formData.page = val;
        this.getList();
      },
      addUser(){
        this.resetFormData();

        this.operateTitle = '新增用户';
        this.dialogFormVisible = true;
      },
      editUser(row) {
        this.operateTitle = '编辑用户';
        this.formData.id = row.id;
        this.formData.uname = row.uname;
        this.formData.authRoleId = row.auth_role_id;
        this.formData.full_name = row.full_name;
        this.formData.mobile = row.mobile;
        this.formData.status = row.status == '1';
        this.formData.password = row.password;

        this.dialogFormVisible = true;
      },
      delUser(row) {
        this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.myfun
              .request("adminUserDelUser.api", { id: row.id }, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            let apiName = this.formData.id === '0' ? 'adminUserAddUser.api' : 'adminUserEditUser.api';
            this.myfun
              .request(apiName, this.formData, "post")
              .then((result) => {
                if (result.code == "1") {
                  this.getList();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  this.dialogFormVisible = false;
                } else {
                  this.$message({
                    message: result.msg,
                    type: "warning",
                  });
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
    created() {
      this.getList();
      this.resetFormData();
    },
    mounted() {
    },
    computed:{
      pageSizs(){
        return this.$store.state.pageSizes;
      }
    }
  }
</script>

<style scoped>

</style>
